import { GET_SERVICE_ALERTS } from "../actions/types";

const serviceAlertsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SERVICE_ALERTS:
      return action.payload;
    default:
      return state;
  }
};

export default serviceAlertsReducer;
