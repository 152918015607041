import React from "react";
import { Alert, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FavoritesBlank() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: `100vh`,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
          width: "100%",
        }}
      >
        <Typography variant="h2" align="center">
          ◔_◔
        </Typography>
        <Alert variant="filled" severity="info" style={{ margin: "16px" }}>
          {t("noBookmarks")}
        </Alert>
      </div>
    </div>
  );
}
