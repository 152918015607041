/* eslint no-unused-vars : "off" */
import React, { useEffect } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useSelector, useDispatch } from "react-redux";
import { useGoogleMap } from "@react-google-maps/api";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemText from "@mui/material/ListItemText";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import {
  Alert,
  Button,
  Chip,
  Divider,
  Fab,
  Grow,
  Skeleton,
  Typography,
  Zoom,
  Stack,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Collapse from "@mui/material/Collapse";

import {
  closeBottomSheet,
  getShapes,
  getStopSchedule,
  setBottomSheetHeight,
  setBottomSheetMoving,
  clearSelectedStop,
  clearShapes,
  clearVehiclesPositions,
} from "../redux/actions";

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import AccessibleIcon from "@mui/icons-material/Accessible";
import WarningIcon from "@mui/icons-material/Warning";
import DirectionsSubwayIcon from "@mui/icons-material/DirectionsSubway";
import ScheduleBottomSheetLoadingSkeleton from "./ScheduleBottomSheetLoadingSkeleton";
import LoadingButton from "@mui/lab/LoadingButton";

import "../Styles/scheduleBottomSheet.css";

import liveAnimation from "../animations/red_circle.gif";
import ServiceAlertsV2 from "./ServiceAlertsV2";

import {
  getDatabase,
  ref as firebaseDBRef,
  query,
  orderByChild,
  push,
  set,
  increment as rtdbIncrement,
  onValue,
  remove,
} from "firebase/database";
import {
  DatabaseProvider,
  useDatabase,
  useDatabaseListData,
  useDatabaseObjectData,
  useFirebaseApp,
  useUser,
  useSigninCheck,
} from "reactfire";
import { useTranslation } from "react-i18next";

export default function ScheduleBottomSheet({ registerPositionWatcher }) {
  const { t } = useTranslation();
  const firstItemNotInPastRef = React.useRef();
  const sheetRef = React.useRef();
  const dispatch = useDispatch();
  const { data: signinResult } = useSigninCheck();
  const schedule = useSelector((state) => state.schedule);
  const selectedStop = useSelector((state) => state.stops.selectedStop);
  const vehicle = useSelector((state) => state.vehicle);
  const vehicleUpdatedPosition = useSelector(
    (state) => state.vehicleUpdatedPosition
  );
  const isBottomSheetOpen = useSelector((state) => state.bottomSheet.toggle);
  const serviceAlerts = useSelector((state) => state.serviceAlerts);
  const map = useGoogleMap();
  const [openSibling, setOpenSibling] = React.useState(false);
  const isBottomSheetMoving = useSelector(
    (state) => state.bottomSheet.isMoving
  );
  const [userBookmarks, setUserBookmarks] = React.useState({});

  const [dontResize, setDontResize] = React.useState(false);
  const [distinctHeader, setDistinctHeader] = React.useState({});

  const firebaseApp = useFirebaseApp();

  const database = getDatabase(firebaseApp);

  console.log(vehicleUpdatedPosition);

  const addBookmark = () => {
    const bookmarkUserRef = firebaseDBRef(
      database,
      `bookmarks/${signinResult?.user.uid}/${selectedStop.stopId}`
    );
    set(bookmarkUserRef, selectedStop);
  };

  React.useEffect(() => {
    if (selectedStop && sheetRef.current) {
      sheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.4);
    }
    setDistinctHeader({});
  }, [selectedStop]);

  React.useEffect(() => {
    if (selectedStop && sheetRef.current && !dontResize) {
      sheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.4);
    }

    if (dontResize && schedule) {
      setDontResize(false);
    }

    firstItemNotInPastRef &&
      firstItemNotInPastRef.current &&
      firstItemNotInPastRef.current.scrollIntoView({ behavior: "smooth" });
  }, [schedule, dontResize, selectedStop]);

  React.useEffect(() => {
    const db = getDatabase();
    const userBookmarksRef = firebaseDBRef(
      db,
      `bookmarks/${signinResult?.user.uid}`
    );
    const unsubscribe = onValue(userBookmarksRef, (snapshot) => {
      const data = snapshot.val();
      setUserBookmarks(data);
    });

    return () => {
      unsubscribe();
    };
  }, [signinResult]);

  const showAlert = () => {
    if (schedule === "ERROR") return false;

    if (!schedule || !serviceAlerts) return false;

    if (schedule.length === 0 || serviceAlerts.alerts === 0) return false;

    let routeIds = schedule.map((element) => {
      return element.trips.routeId.toString();
    });

    let shouldShowAlert = false;

    serviceAlerts.alerts.forEach((element) => {
      element.informed_entities.forEach((element) => {
        if (routeIds.includes(element.route_short_name)) shouldShowAlert = true;
      });
    });

    return shouldShowAlert;
  };

  const handleLiveTrackingClick = (trip, tripId) => {
    registerPositionWatcher();
    let bottomSheetHeight;
    if (selectedStop && sheetRef.current) {
      sheetRef.current.snapTo(({ maxHeight }) => {
        bottomSheetHeight = maxHeight * 0.4;
        return maxHeight * 0.4;
      });
    }

    if (tripId) {
      dispatch(getShapes(tripId));
    }

    if (map && trip && selectedStop) {
      let bounds = new window.google.maps.LatLngBounds();
      bounds.extend(
        new window.google.maps.LatLng(
          selectedStop.stopLat,
          selectedStop.stopLon
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          trip.vehicle.position.latitude,
          trip.vehicle.position.longitude
        )
      );
      map.fitBounds(bounds, {
        bottom: bottomSheetHeight,
        top: 75,
        right: 20,
        left: 20,
      });
    }
  };

  const handleStationTitleClicked = () => {
    registerPositionWatcher();
    if (map && selectedStop) {
      map.panTo(
        new window.google.maps.LatLng(
          selectedStop.stopLat,
          selectedStop.stopLon
        )
      );
      map.setZoom(17);
    }
    if (selectedStop && sheetRef.current) {
      sheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.4);
    }
  };

  const renderOccupancyStatus = (trip) => {
    switch (trip.vehicle.occupancy_status) {
      case 1:
        return t("nearlyEmpty");
      case 2:
        return t("someSeatsAvailable");
      case 3:
        return t("standingRoom");
      case 4:
        return t("nearlyFull");
      default:
        return t("nearlyEmpty");
    }
  };

  const renderBusIcon = (wheelchairAccessible) => {
    if (wheelchairAccessible === 1) {
      return (
        <>
          <DirectionsBusIcon />
          <AccessibleIcon />
        </>
      );
    }

    return <DirectionsBusIcon />;
  };

  const renderUpdatedTime = (tripId, regularTime, itemInPast) => {
    console.log(tripId);
    if (vehicleUpdatedPosition && vehicleUpdatedPosition.updateDetails) {
      let v = regularTime;
      let isDelayed = false;
      let isEarly = false;
      let isInThePast = true;

      vehicleUpdatedPosition.updateDetails.forEach((innerElement) => {
        if (innerElement.arrivalTime) {
          var options = { hour12: false, hour: "2-digit", minute: "2-digit" };

          var myDateNow = new Date(Date.now());
          var myFormattedDateNow = myDateNow.toLocaleTimeString(
            "en-US",
            options
          );

          var myDate = new Date(Number(innerElement.arrivalTime) * 1000);

          if (innerElement.tripdId === tripId)
            v = myDate.toLocaleTimeString("en-US", options); //hr + ":" + m;

          if (myFormattedDateNow < v) {
            isInThePast = false;
          } else isInThePast = true;

          if (regularTime === v) {
            isDelayed = false;
            isEarly = false;
          } else if (v > regularTime) isDelayed = true;
          else isEarly = true;
        }
      });

      return (
        <Stack direction="column" spacing={0}>
          {isDelayed || isEarly ? (
            <Typography
              edge="end"
              checked={true}
              style={{ textDecoration: "line-through" }}
              align="center"
            >
              {regularTime}
            </Typography>
          ) : null}
          <Typography
            edge="end"
            checked={true}
            style={{
              textDecoration: itemInPast && isInThePast ? "line-through" : "",
            }}
            align="center"
          >
            {v}
          </Typography>
          {itemInPast && isInThePast ? (
            <Chip label="Missed" disabled sx={{ borderRadius: 2 }} />
          ) : isDelayed ? (
            <Chip label="Delayed" color="error" sx={{ borderRadius: 2 }} />
          ) : isEarly ? (
            <Chip label="Early" color="warning" sx={{ borderRadius: 2 }} />
          ) : (
            <Chip label="On Time" color="success" sx={{ borderRadius: 2 }} />
          )}
        </Stack>
      );
    }
  };

  const renderSchedule = () => {
    const d = new Date();
    let datetext = d.toTimeString();
    datetext = datetext.split(" ")[0].slice(0, -3);

    if (!schedule) return <ScheduleBottomSheetLoadingSkeleton />;

    if (schedule === "ERROR")
      return <Alert severity="error">{t("errorMessage2")}</Alert>;

    if (schedule.length === 0) {
      return (
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <DirectionsSubwayIcon />
            </ListItemIcon>
            <ListItemText primary={t("scheduleNotAvailable")} />
          </ListItemButton>
        </ListItem>
      );
    }

    let refUpdated = false;

    return schedule.map((element) => {
      let trip = null;
      let shouldUpdateRef = false;

      const itemInPast = datetext > element.stopTimes.arrivalTime.slice(0, -3);

      if (itemInPast) {
        //past
      } else if (refUpdated === false) {
        //future
        shouldUpdateRef = true;
        refUpdated = true;
      }

      if (vehicle && vehicle.length > 0) {
        trip = vehicle.find((v) => {
          return v.vehicle.trip.tripId === element.trips.tripId;
        });
      }

      return (
        <Collapse
          in={
            distinctHeader[element.trips.tripHeadsign] &&
            distinctHeader[element.trips.tripHeadsign].isSelected
          }
          key={element.trips.tripId}
        >
          <div
            key={element.trips.tripId}
            ref={shouldUpdateRef ? firstItemNotInPastRef : null}
          >
            <ListItem
              disablePadding
              key={element.trips.tripId}
              onClick={() =>
                handleLiveTrackingClick(trip, element.trips.tripId)
              }
            >
              <ListItemButton>
                <ListItemIcon>
                  {renderBusIcon(element.trips.wheelchairAccessible)}
                </ListItemIcon>
                {trip ? (
                  <>
                    <ListItemText
                      primary={element.trips.tripHeadsign}
                      secondary={renderOccupancyStatus(trip)}
                    />
                    <Chip
                      label={t("liveTracking")}
                      size="small"
                      variant="outlined"
                      style={{ marginRight: "12px" }}
                      color="error"
                      icon={
                        <img
                          src={liveAnimation}
                          alt="live tracking on"
                          style={{ height: "100%" }}
                        />
                      }
                    />
                  </>
                ) : (
                  <ListItemText
                    primary={element.trips.tripHeadsign}
                    secondary={"N/A"}
                  />
                )}
                {renderUpdatedTime(
                  element.trips.tripId,
                  element.stopTimes.arrivalTime.slice(0, -3),
                  itemInPast
                )}
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        </Collapse>
      );
    });
  };

  const handleRefreshData = () => {
    setDontResize(true);
    dispatch(getStopSchedule(selectedStop));
  };

  const renderFilterList = () => {
    if (!schedule || schedule === "ERROR") return null;

    schedule.forEach((element) => {
      if (!distinctHeader[element.trips.tripHeadsign]) {
        distinctHeader[element.trips.tripHeadsign] = {
          tripHeadsign: element.trips.tripHeadsign,
          isSelected: true,
        };
      }
    });

    const filterArray = [];

    let selectedCount = 0;
    for (const [key, value] of Object.entries(distinctHeader)) {
      if (value.isSelected) selectedCount += 1;
    }

    for (const [key, value] of Object.entries(distinctHeader)) {
      filterArray.push(
        <Chip
          key={key}
          label={key}
          variant="filled"
          clickable={false}
          style={{ marginRight: 8 }}
          color={value.isSelected ? "secondary" : "default"}
          onClick={
            selectedCount === 1 && value.isSelected
              ? () => {}
              : () => {
                  setDistinctHeader({
                    ...distinctHeader,
                    [key]: { tripHeadsign: key, isSelected: !value.isSelected },
                  });
                }
          }
        />
      );
    }

    return filterArray;
  };

  const renderHeader = () => {
    return (
      <>
        <Button
          onClick={handleStationTitleClicked}
          size="large"
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: "16px",
            marginLeft: "8px",
            marginRight: "8px",
            marginBottom: "8px",
            width: "auto",
          }}
          startIcon={
            selectedStop.wheelchairBoarding === 1 ? <AccessibleIcon /> : null
          }
        >
          {selectedStop && selectedStop.stopName}
        </Button>
        {userBookmarks && Object.keys(userBookmarks).length > 15 ? (
          <Alert severity="info" sx={{ marginBottom: "8px" }}>
            {t("bookmarkLimit")}
          </Alert>
        ) : null}
        <div
          id="verticalScroll"
          style={{
            width: "100%",
            whiteSpace: "nowrap",
            position: "relative",
            overflowX: "scroll",
            overflowY: "hidden",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            textAlign: "start",
            marginBottom: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              dispatch(closeBottomSheet());
              dispatch(clearVehiclesPositions());
              dispatch(clearShapes());
              dispatch(clearSelectedStop());
            }}
            startIcon={<CloseIcon />}
            style={{ marginRight: 8, marginLeft: 8 }}
            color="error"
          >
            {t("close")}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleRefreshData}
            startIcon={<RefreshIcon />}
            style={{ marginRight: 8 }}
            color="success"
            loading={!schedule}
            loadingPosition="start"
          >
            {t("refresh")}
          </LoadingButton>
          {userBookmarks && userBookmarks[selectedStop.stopId] ? (
            <LoadingButton
              variant="outlined"
              startIcon={<BookmarkAddedIcon />}
              style={{ marginRight: 8 }}
              color="primary"
              loading={!schedule}
              loadingPosition="start"
            >
              {t("bookmarked")}
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              onClick={
                userBookmarks && Object.keys(userBookmarks).length > 15
                  ? null
                  : addBookmark
              }
              startIcon={<BookmarkAddIcon />}
              style={{ marginRight: 8 }}
              color="primary"
              loading={!schedule}
              loadingPosition="start"
              disabled={
                userBookmarks && Object.keys(userBookmarks).length > 15
                  ? true
                  : false
              }
            >
              {t("bookmark")}
            </LoadingButton>
          )}
          <Grow in={showAlert()} {...(showAlert() ? { timeout: 1000 } : {})}>
            <Button
              label="Stop(s) Moved"
              variant="contained"
              startIcon={<WarningIcon />}
              style={{ marginRight: 8 }}
              onClick={() => {
                setOpenSibling(true);
              }}
              color="warning"
            >
              {t("stopsMoved")}
            </Button>
          </Grow>
        </div>
        {schedule && schedule !== "ERROR" ? (
          <div style={{ margin: 8 }}>
            <div style={{ float: "left" }}>
              <FilterListIcon />
            </div>
            <div
              id="verticalScroll"
              style={{
                whiteSpace: "nowrap",
                position: "relative",
                overflowX: "scroll",
                overflowY: "hidden",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                textAlign: "right",
                marginTop: "8px",
              }}
            >
              {renderFilterList()}
            </div>
          </div>
        ) : schedule === "ERROR" ? null : (
          <Skeleton
            variant="text"
            height={45}
            style={{ marginRight: "8px", marginLeft: "8px" }}
          />
        )}
      </>
    );
  };

  return (
    <BottomSheet
      open={isBottomSheetOpen}
      ref={sheetRef}
      blocking={false}
      snapPoints={({ headerHeight, maxHeight }) => [
        headerHeight,
        maxHeight * 0.4,
        maxHeight,
      ]}
      defaultSnap={({ maxHeight }) => maxHeight * 0.4}
      header={selectedStop && renderHeader()}
      onSpringStart={() => {
        dispatch(setBottomSheetHeight(sheetRef.current.height));
        requestAnimationFrame(() => {
          dispatch(setBottomSheetHeight(sheetRef.current.height));
        });
      }}
      onSpringEnd={() => {
        dispatch(setBottomSheetHeight(sheetRef.current.height));
      }}
      onTouchMove={() => dispatch(setBottomSheetMoving(true))}
      onTouchEnd={() => dispatch(setBottomSheetMoving(false))}
      sibling={
        // <ServiceAlerts open={openSibling} close={() => setOpenSibling(false)} />
        <ServiceAlertsV2
          open={openSibling}
          close={() => setOpenSibling(false)}
        />
      }
    >
      <>
        <Collapse in={true}>
          <List style={{ paddingBottom: 86 }}>{renderSchedule()}</List>
        </Collapse>
        <Zoom
          in={
            !isBottomSheetMoving &&
            sheetRef &&
            sheetRef.current &&
            sheetRef.current.height === window.innerHeight
          }
        >
          <Fab
            color="primary"
            aria-label="load more stations"
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              marginBottom: 16,
              marginRight: 8,
            }}
            onClick={() =>
              sheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.4)
            }
          >
            <KeyboardArrowDownIcon />
          </Fab>
        </Zoom>
      </>
    </BottomSheet>
  );
}
