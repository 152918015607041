import React from "react";
import {
  ListItem,
  Typography,
  ListItemText,
  Divider,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FavoriteSmallSchedule({ loading, schedule }) {
  const { t } = useTranslation();
  if (loading) {
    return (
      <>
        <ListItem>
          <Skeleton variant="text" height={28} sx={{ width: "100%" }} />
        </ListItem>
        <Divider />
        <ListItem>
          <Skeleton variant="text" height={28} sx={{ width: "100%" }} />
        </ListItem>
        <Divider />
        <ListItem>
          <Skeleton variant="text" height={28} sx={{ width: "100%" }} />
        </ListItem>
        <Divider />
      </>
    );
  }

  if (schedule.length === 0) {
    return (
      <>
        <ListItem>
          <ListItemText primary={t("scheduleNotAvailable")} />
        </ListItem>
        <Divider />
      </>
    );
  }

  return schedule.map((element) => {
    return (
      <React.Fragment key={element.trips.tripId}>
        <ListItem key={element.trips.tripId}>
          <ListItemText primary={element.trips.tripHeadsign} />
          <Typography edge="end" checked={true}>
            {element.stopTimes.arrivalTime.slice(0, -3)}
          </Typography>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  });
}
