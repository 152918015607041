export const GET_STOPS_LOCATIONS = "GET_STOPS_LOCATIONS";
export const LOAD_STOPS_LOCATIONS = "LOAD_STOPS_LOCATIONS";
export const SELECT_STOP_LOCATION = "SELECT_STOP_LOCATION";
export const GET_STOP_SCHEDULE = "GET_STOP_SCHEDULE";
export const LOAD_STOP_SCHEDULE = "LOAD_STOP_SCHEDULE";
export const GET_VEHICLES_POSITIONS = "GET_VEHICLES_POSITIONS";
export const LOAD_VEHICLES_POSITIONS = "LOAD_VEHICLES_POSITIONS";
export const SET_MAP_ZOOM = "SET_MAP_ZOOM";
export const OPEN_BOTTOM_SHEET = "OPEN_BOTTOM_SHEET";
export const CLOSE_BOTTOM_SHEET = "CLOSE_BOTTOM_SHEET";
export const SET_BOTTOM_SHEET_HEIGHT = "SET_BOTTOM_SHEET_HEIGHT";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_BOTTOM_SHEET_MOVING = "SET_BOTTOM_SHEET_MOVING";
export const GET_SHAPES = "GET_SHAPES";
export const GET_SERVICE_ALERTS = "GET_SERVICE_ALERTS";
export const CLEAR_SELECTED_STOP = "CLEAR_SELECTED_STOP";
export const CLEAR_SHAPES = "CLEAR_SHAPES";
export const CLEAR_VEHICLES_POSITIONS = "CLEAR_VEHICLES_POSITIONS";
export const SET_USER_CREDENTIALS = "SET_USER_CREDENTIALS";
export const CLEAR_USER_CREDENTIALS = "CLEAR_USER_CREDENTIALS";
export const LOAD_METRO_SERVICE = "LOAD_METRO_SERVICE";
export const GET_METRO_SERVICE = "GET_METRO_SERVICE";
export const LOAD_VEHICLES_UPDATED_POSITIONS =
  "LOAD_VEHICLES_UPDATED_POSITIONS";
export const GET_VEHICLES_UPDATED_POSITIONS = "GET_VEHICLES_UPDATED_POSITIONS";
