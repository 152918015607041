import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import SimpleReactLightbox from "simple-react-lightbox";
import { FirebaseAppProvider } from "reactfire";

import App from "./components/App";
import reducers from "./redux/reducers";

import "./i18n/config";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const firebaseConfig = {
  apiKey: "AIzaSyC90Dlxh4tNmE52DBS7X7aLGu4b_XoDRT0",
  authDomain: "mtl-bus-metro-and-train-sched.firebaseapp.com",
  databaseURL:
    "https://mtl-bus-metro-and-train-sched-default-rtdb.firebaseio.com",
  projectId: "mtl-bus-metro-and-train-sched",
  storageBucket: "mtl-bus-metro-and-train-sched.appspot.com",
  messagingSenderId: "658797959255",
  appId: "1:658797959255:web:fc06a6cddee58af713ab12",
  measurementId: "G-8E43DLE4ND",
};

ReactDOM.render(
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SimpleReactLightbox>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SimpleReactLightbox>
    </FirebaseAppProvider>
  </Provider>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
