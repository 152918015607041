import { GET_SHAPES, CLEAR_SHAPES } from "../actions/types";

const shapesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SHAPES:
      return action.payload;
    case CLEAR_SHAPES:
      return null;
    default:
      return state;
  }
};

export default shapesReducer;
