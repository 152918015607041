import {
  GET_STOPS_LOCATIONS,
  LOAD_STOPS_LOCATIONS,
  SELECT_STOP_LOCATION,
  GET_STOP_SCHEDULE,
  LOAD_STOP_SCHEDULE,
  GET_VEHICLES_POSITIONS,
  LOAD_VEHICLES_POSITIONS,
  SET_MAP_ZOOM,
  OPEN_BOTTOM_SHEET,
  CLOSE_BOTTOM_SHEET,
  SET_BOTTOM_SHEET_HEIGHT,
  SET_USER_LOCATION,
  SET_BOTTOM_SHEET_MOVING,
  GET_SHAPES,
  GET_SERVICE_ALERTS,
  CLEAR_SELECTED_STOP,
  CLEAR_SHAPES,
  CLEAR_VEHICLES_POSITIONS,
  SET_USER_CREDENTIALS,
  CLEAR_USER_CREDENTIALS,
  LOAD_METRO_SERVICE,
  GET_METRO_SERVICE,
  LOAD_VEHICLES_UPDATED_POSITIONS,
  GET_VEHICLES_UPDATED_POSITIONS,
} from "./types";
import montrealDataApi from "../../apis/montrealData";
import { reactLocalStorage } from "reactjs-localstorage";

export const getStopsLocations = (bounds) => async (dispatch) => {
  dispatch({ type: LOAD_STOPS_LOCATIONS });

  montrealDataApi
    .get(
      `Stops?nelat=${bounds.ne.lat}&nelng=${bounds.ne.lng}&swlat=${bounds.sw.lat}&swlng=${bounds.sw.lng}&`
    )
    .then(function (response) {
      dispatch({ type: GET_STOPS_LOCATIONS, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: GET_STOPS_LOCATIONS, payload: "ERROR" });
    });
};

export const getAllStopsLocations = () => async (dispatch) => {
  dispatch({ type: LOAD_STOPS_LOCATIONS });

  montrealDataApi
    .get(`Stops/GetAllStops`)
    .then(function (response) {
      dispatch({ type: GET_STOPS_LOCATIONS, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: GET_STOPS_LOCATIONS, payload: "ERROR" });
    });
};

export const getStopSchedule = (stop) => async (dispatch) => {
  dispatch({ type: SELECT_STOP_LOCATION, payload: stop });
  dispatch({ type: LOAD_STOP_SCHEDULE });
  dispatch({ type: LOAD_VEHICLES_UPDATED_POSITIONS });
  dispatch({ type: OPEN_BOTTOM_SHEET });

  try {
    const response = await montrealDataApi.get(
      `StopSchedule?stopdId=${stop.stopId}`
    );

    let tripIds = [];
    response.data.forEach((element) => {
      tripIds.push(element.trips.tripId);
    });

    const response1 = await montrealDataApi.get(
      `TripUpdates/TripUpdatesByStopAndTrip?stopId=${stop.stopId}`,
      {
        params: { tripIds: tripIds },
      }
    );

    console.log(response.data);
    console.log(response1.data);

    dispatch({ type: GET_STOP_SCHEDULE, payload: response.data });
    dispatch({ type: GET_VEHICLES_UPDATED_POSITIONS, payload: response1.data });
  } catch {
    dispatch({ type: GET_STOP_SCHEDULE, payload: "ERROR" });
  }
};

export const getVehiclesPositions = (tripIds) => async (dispatch) => {
  dispatch({ type: LOAD_VEHICLES_POSITIONS });

  const response = await montrealDataApi.get("VehiclePosition", {
    params: { tripIds: tripIds },
  });

  dispatch({ type: GET_VEHICLES_POSITIONS, payload: response.data });
};

export const setMapZoom = (zoomLevel) => {
  return {
    type: SET_MAP_ZOOM,
    payload: zoomLevel,
  };
};

export const openBottomSheet = () => {
  return {
    type: OPEN_BOTTOM_SHEET,
  };
};

export const closeBottomSheet = () => {
  return {
    type: CLOSE_BOTTOM_SHEET,
  };
};

export const setBottomSheetHeight = (height) => {
  return {
    type: SET_BOTTOM_SHEET_HEIGHT,
    payload: height,
  };
};

export const setBottomSheetMoving = (bottomSheetMoving) => {
  return {
    type: SET_BOTTOM_SHEET_MOVING,
    payload: bottomSheetMoving,
  };
};

export const setUserLocation = (location) => {
  return {
    type: SET_USER_LOCATION,
    payload: location,
  };
};

export const getShapes = (tripId) => async (dispatch) => {
  const response = await montrealDataApi.get(`Shapes?tripId=${tripId}`);
  dispatch({ type: GET_SHAPES, payload: response.data });
};

export const getServiceAlerts = () => async (dispatch) => {
  montrealDataApi
    .get("ServiceAlerts")
    .then(function (response) {
      dispatch({ type: GET_SERVICE_ALERTS, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: GET_SERVICE_ALERTS, payload: "ERROR" });
    });
};

export const clearSelectedStop = () => {
  return {
    type: CLEAR_SELECTED_STOP,
  };
};

export const clearShapes = () => {
  return {
    type: CLEAR_SHAPES,
  };
};

export const clearVehiclesPositions = () => {
  return {
    type: CLEAR_VEHICLES_POSITIONS,
  };
};

export const setUserCredentials = (userCredentials) => {
  return {
    type: SET_USER_CREDENTIALS,
    payload: userCredentials,
  };
};

export const clearUserCredentials = () => {
  return {
    type: CLEAR_USER_CREDENTIALS,
  };
};

export const getMetroServiceAlerts = () => async (dispatch) => {
  dispatch({ type: LOAD_METRO_SERVICE });

  let url = "MetroService";
  const lang = reactLocalStorage.get("lang");
  if (lang) {
    url = `MetroService?lang=${lang}`;
  }

  montrealDataApi
    .get(url)
    .then(function (response) {
      dispatch({ type: GET_METRO_SERVICE, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: GET_METRO_SERVICE, payload: "ERROR" });
    });
};

export const getTripUpdates = (tripIds, stopdId) => async (dispatch) => {
  dispatch({ type: LOAD_VEHICLES_UPDATED_POSITIONS });

  const response = await montrealDataApi.get(
    "TripUpdates/TripUpdatesByStopAndTrip",
    {
      params: { tripIds: tripIds, stopdId: stopdId },
    }
  );

  dispatch({ type: GET_VEHICLES_UPDATED_POSITIONS, payload: response.data });
};
