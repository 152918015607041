import * as React from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import SubwayIcon from "@mui/icons-material/Subway";

import { useTranslation } from "react-i18next";

export default function SimpleBottomNavigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname.substring(1));

  React.useEffect(() => {
    setValue(location.pathname.substring(1));
  }, [location]);

  const handleChange = (event, newValue) => {
    navigate(`/${newValue}`);
  };

  return (
    <>
      <Outlet />
      <Box sx={{ width: "100%", bottom: 0, position: "fixed" }}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction
            label={t("nearby")}
            icon={<LocationOnIcon />}
            value={""}
          />
          <BottomNavigationAction
            label={t("metro")}
            icon={<SubwayIcon />}
            value={"metro"}
          />
          <BottomNavigationAction
            label={t("settings")}
            icon={<SettingsIcon />}
            value={"settings"}
          />
        </BottomNavigation>
      </Box>
    </>
  );
}
