import * as React from "react";

import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { Divider, Fab, Typography } from "@mui/material";

import { reactLocalStorage } from "reactjs-localstorage";
import { useTranslation } from "react-i18next";

export default function ServiceAlertsV2({ close, open }) {
  const { t } = useTranslation();
  const schedule = useSelector((state) => state.schedule);
  const selectedStop = useSelector((state) => state.stops.selectedStop);
  const serviceAlerts = useSelector((state) => state.serviceAlerts);

  const renderAlerts = () => {
    if (!schedule || !serviceAlerts || schedule === "ERROR") return null;

    if (schedule.length === 0 || serviceAlerts.alerts === 0) return null;

    let routeIds = schedule.map((element) => {
      return element.trips.routeId.toString();
    });

    console.log(serviceAlerts);

    let serviceAlertsDict = {};
    serviceAlerts.alerts.forEach((element) => {
      if (!routeIds.includes(element.informed_entities[0].route_short_name)) {
        return;
      }

      if (!serviceAlertsDict[element.informed_entities[0].route_short_name]) {
        serviceAlertsDict[element.informed_entities[0].route_short_name] = [];
      }

      let isDuplicateDescription = false;

      serviceAlertsDict[element.informed_entities[0].route_short_name].forEach(
        (element1) => {
          if (
            element1.englishDescription === element.description_texts[1].text
          ) {
            isDuplicateDescription = true;
            return;
          }
        }
      );

      if (isDuplicateDescription) return;

      serviceAlertsDict[element.informed_entities[0].route_short_name].push({
        englishDescription: element.description_texts[1].text,
        frenchDescription: element.description_texts[0].text,
        start: element.active_periods[0].start,
        end: element.active_periods[0].end,
      });
    });

    if (serviceAlertsDict.length === 0) {
      return null;
    }

    let displayList = [];

    for (const [key, element] of Object.entries(serviceAlertsDict)) {
      displayList.push(
        <Accordion key={key} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Chip
              key={key}
              label={key}
              variant="filled"
              clickable={false}
              color={"secondary"}
            />
          </AccordionSummary>
          <AccordionDetails>
            {element.map((element) => {
              return (
                <div key={element.englishDescription}>
                  <Typography variant="body2">
                    {reactLocalStorage.get("lang") === "en"
                      ? element.englishDescription
                      : element.frenchDescription}
                  </Typography>
                  <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                </div>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    }
    return displayList;
  };

  const renderContent = () => {
    return (
      <>
        <List
          subheader={
            <ListSubheader component="div" id="neste1d-list-subheader">
              {selectedStop && selectedStop.stopName}
            </ListSubheader>
          }
        ></List>
        {renderAlerts()}

        <Fab
          variant="extended"
          color="primary"
          aria-label="load more stations"
          onClick={close}
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            marginRight: 8,
            marginBottom: 16,
          }}
        >
          <CloseIcon />
          {t("close")}
        </Fab>
      </>
    );
  };

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={open}
            onClose={close}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: "auto",
                height: "90%",
                borderTopRightRadius: "25px",
                borderTopLeftRadius: "25px",
              },
            }}
          >
            {renderContent()}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
