import * as React from "react";
import { useAuth, useSigninCheck } from "reactfire";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { reactLocalStorage } from "reactjs-localstorage";

import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const signOut = (auth) =>
  auth.signOut().then(() => {
    reactLocalStorage.remove("token");
  });

const signIn = async (auth) => {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
  } catch {
    //do nothing
  }
};

const UserDetails = ({ user }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <List>
        <ListItem>
          <ListItemAvatar>
            {user.photoURL ? (
              <Avatar src={user.photoURL} />
            ) : (
              <Avatar>{user.displayName.charAt(0)}</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={user.displayName}
            secondary={user.email}
          ></ListItemText>
          <Button
            variant="outlined"
            onClick={() => signOut(auth)}
            style={{ margin: "auto" }}
          >
            {t("signout")}
          </Button>
        </ListItem>
      </List>
    </>
  );
};

const SignInForm = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Divider sx={{ marginBottom: 2 }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={() => signIn(auth)}
          style={{ margin: "auto" }}
        >
          {t("signinWithGoogle")}
        </Button>
      </div>
      <Divider sx={{ marginTop: 2 }} />
    </>
  );
};

export const Auth = () => {
  const { status, data: signinResult } = useSigninCheck();

  if (status === "loading") {
    return <CircularProgress />;
  }

  const { signedIn, user } = signinResult;

  if (signedIn === true && user.isAnonymous === false) {
    return <UserDetails user={user} />;
  } else {
    return <SignInForm />;
  }
};
