import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { TransitLayer } from "@react-google-maps/api";

import { useTheme } from "@mui/material/styles";

import { getStopsLocations, setUserLocation } from "../redux/actions";
import StopMarkers from "./StopMarkers";
import VehicleMarkers from "./VehicleMarkers";
import ScheduleBottomSheet from "./ScheduleBottomSheet";
import UserLocationMarker from "./UserLocationMarker";
import ShapesPolylines from "./ShapesPolylines";
import MyLocationButton from "./MyLocationButton";
import ZoomInToShowStopsButton from "./ZoomInToShowStopsButton";
import { LightMapTheme } from "../mapStyles/LightMapTheme";

import "react-spring-bottom-sheet/dist/style.css";

import { GOOGLE_MAPS_API_KEY } from "../secrets";
import FavoritesDrawer from "./FavoritesDrawer";

const center = {
  lat: 45.5125898,
  lng: -73.5580612,
};

export default function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mapRef = React.useRef();
  const [showRefreshButton, setShowRefreshButton] = React.useState(false);
  const [map, setMap] = React.useState(null);
  const [bounds, setBounds] = React.useState({
    ne: { lat: 0, lng: 0 },
    sw: { lat: 0, lng: 0 },
  });
  const selectedStop = useSelector((state) => state.stops.selectedStop);
  const bottomSheetHeight = useSelector((state) => state.bottomSheet.height);
  const isBottomSheetMoving = useSelector(
    (state) => state.bottomSheet.isMoving
  );

  const [locationWatcherId, setLocationWatcherId] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  React.useEffect(() => {
    if (selectedStop && map) {
      map.panTo(
        new window.google.maps.LatLng(
          selectedStop.stopLat,
          selectedStop.stopLon
        )
      );
    }
  }, [selectedStop, map]);

  const moveMap = (map, position) => {
    if (position && map) {
      map.panTo(
        new window.google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        )
      );
    }
  };

  const registerPositionWatcher = (map) => {
    unregisterPositionWatcher();
    const watcherId = navigator.geolocation.watchPosition(
      function (position) {
        if (position.coords.latitude !== 0 && position.coords.longitude !== 0) {
          dispatch(
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              accuracy: position.coords.accuracy,
            })
          );
        }
        moveMap(map, position);
      },
      function error(err) {
        console.error("ERROR(" + err.code + "): " + err.message);
      },
      { maximumAge: 60000, timeout: 10000, enableHighAccuracy: false }
    );
    setLocationWatcherId(watcherId);
  };

  const onLoad = (map) => {
    setMap(map);
    registerPositionWatcher(map);
  };

  const onUnmount = () => {
    setMap(null);
    unregisterPositionWatcher();
  };

  const unregisterPositionWatcher = () => {
    if (locationWatcherId) {
      navigator.geolocation.clearWatch(locationWatcherId);
    }
  };

  const onBoundsChanged = () => {
    if (map) {
      let ne = map.getBounds().getNorthEast();
      let sw = map.getBounds().getSouthWest();
      let swne = {
        ne: { lat: ne.lat(), lng: ne.lng() },
        sw: { lat: sw.lat(), lng: sw.lng() },
      };
      setBounds(swne);
      if (mapRef.current.state.map.zoom >= 16) {
        //dispatch(getStopsLocations(swne));
        setShowRefreshButton(false);
      } else {
        setShowRefreshButton(true);
      }
    }
  };

  const getUserPosition = (map) => {
    registerPositionWatcher(map);
  };

  return isLoaded ? (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "calc(100% - " + theme.mixins.toolbar.minHeight + "px)",
      }}
    >
      <GoogleMap
        ref={mapRef}
        options={{
          styles: LightMapTheme,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={center}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onIdle={onBoundsChanged}
        onDragStart={() => {
          unregisterPositionWatcher();
          registerPositionWatcher();
        }}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <StopMarkers
          registerPositionWatcher={registerPositionWatcher}
          bounds={bounds}
          zoom={mapRef && mapRef.current ? mapRef.current.state.map.zoom : 10}
        />
        <VehicleMarkers />
        <UserLocationMarker />
        <TransitLayer />
        <ScheduleBottomSheet
          registerPositionWatcher={registerPositionWatcher}
        />
        <ShapesPolylines />
      </GoogleMap>
      <MyLocationButton
        bottomSheetHeight={bottomSheetHeight}
        showRefreshButton={showRefreshButton}
        isBottomSheetMoving={isBottomSheetMoving}
        getUserPosition={() => {
          getUserPosition(map);
          map.setZoom(17);
        }}
        map={map}
      />
      <ZoomInToShowStopsButton
        showRefreshButton={showRefreshButton}
        isBottomSheetMoving={isBottomSheetMoving}
        bottomSheetHeight={bottomSheetHeight}
        getStopsLocations={getStopsLocations}
        zoomIn={() => map.setZoom(16)}
        setShowRefreshButton={setShowRefreshButton}
        bounds={bounds}
      />
      <FavoritesDrawer
        bottomSheetHeight={bottomSheetHeight}
        showRefreshButton={showRefreshButton}
        isBottomSheetMoving={isBottomSheetMoving}
      />
    </div>
  ) : (
    <></>
  );
}
