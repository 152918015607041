import React from "react";
import { Offline, Online } from "react-detect-offline";
import { getAuth } from "firebase/auth";
import { AuthProvider, useFirebaseApp } from "reactfire";

import { ThemeProvider } from "@mui/material/styles";

import Routes from "./Routes";
import OfflinePage from "./OfflinePage";
import { theme } from "../Styles/theme";
import { CssBaseline } from "@mui/material";

export default function App() {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider sdk={auth}>
        <CssBaseline>
            <Routes />
        </CssBaseline>
      </AuthProvider>
    </ThemeProvider>
  );
}
