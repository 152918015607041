import React from "react";
import { useNavigate } from "react-router-dom";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import MapIcon from "@mui/icons-material/Map";
import ListItemButton from "@mui/material/ListItemButton";
import { Divider, ListSubheader } from "@mui/material";
import ElevatorIcon from "@mui/icons-material/ElevatorOutlined";

import MetroServiceStatus from "./MetroServiceStatus";
import metroPng from "../assets/metro_map.png";

import { useTranslation } from "react-i18next";

export default function Metro() {
  const { t } = useTranslation();
  const { openLightbox } = useLightbox();
  const navigate = useNavigate();

  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: true,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    caption: {
      showCaption: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <div>
      <MetroServiceStatus />
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("other")}
          </ListSubheader>
        }
      >
        <ListItem onClick={() => openLightbox()}>
          <ListItemButton disableGutters={true}>
            <ListItemAvatar>
              <Avatar variant="rounded" sx={{ bgcolor: "#ff0099" }}>
                <MapIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t("metroMap")} />
          </ListItemButton>
        </ListItem>
        <ListItem onClick={() => navigate("/elevatorstatus")}>
          <ListItemButton disableGutters={true}>
            <ListItemAvatar>
              <Avatar variant="rounded" sx={{ bgcolor: "#2196f3" }}>
                <ElevatorIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t("elevatorServiceStatus")} />
          </ListItemButton>
        </ListItem>
      </List>
      <SRLWrapper options={options}>
        <a href={metroPng}>
          <img src={metroPng} alt="metro map" width="0px" />
        </a>
      </SRLWrapper>
    </div>
  );
}
