import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { getMetroServiceAlerts } from "../redux/actions";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import { blue, yellow, green, deepOrange } from "@mui/material/colors";
import { IconButton, ListSubheader, Skeleton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useTranslation } from "react-i18next";

export default function MetroServiceStatus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const metroService = useSelector((state) => state.metro);

  const getMetroColor = (id) => {
    switch (id) {
      case "1":
        return { bgcolor: green[500] };
      case "2":
        return { bgcolor: deepOrange[500] };
      case "4":
        return { bgcolor: yellow[500] };
      case "5":
        return { bgcolor: blue[500] };
      default:
        return {};
    }
  };

  const renderLoading = () => {
    return ["1", "2", "3", "4"].map((element) => {
      return (
        <ListItem key={element}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton variant="text" width="100%" />}
            secondary={<Skeleton variant="text" width="40%" />}
          />
        </ListItem>
      );
    });
  };

  if (!metroService) {
    return (
      <div>
        <List
          subheader={
            <ListSubheader component="div" id="metro-service-status-subheader">
              {t("metroServiceStatus")}
              <IconButton aria-label="refresh" color="secondary" disabled>
                <RefreshIcon />
              </IconButton>
            </ListSubheader>
          }
        >
          {renderLoading()}
        </List>
      </div>
    );
  }

  let metroServiceArray = [];

  for (const [key, element] of Object.entries(metroService.metro)) {
    metroServiceArray.push(
      <ListItem key={key.toString()}>
        <ListItemAvatar>
          <Avatar sx={getMetroColor(key)}>
            <DirectionsTransitIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={element.name}
          secondary={ReactHtmlParser(element.data.text)}
        />
      </ListItem>
    );
  }

  return (
    <div>
      <List
        subheader={
          <ListSubheader component="div" id="metro-service-status-subheader">
            {t("metroServiceStatus")}
            <IconButton
              aria-label="refresh"
              onClick={() => dispatch(getMetroServiceAlerts())}
            >
              <RefreshIcon />
            </IconButton>
          </ListSubheader>
        }
      >
        {metroServiceArray}
      </List>
    </div>
  );
}
