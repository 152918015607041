import { GET_STOP_SCHEDULE, LOAD_STOP_SCHEDULE } from "../actions/types";

const scheduleReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STOP_SCHEDULE:
      return action.payload;
    case LOAD_STOP_SCHEDULE:
      return null;
    default:
      return state;
  }
};

export default scheduleReducer;
