import {
  LOAD_VEHICLES_UPDATED_POSITIONS,
  GET_VEHICLES_UPDATED_POSITIONS,
} from "../actions/types";

const vehicleUpdatedPositionReducer = (state = null, action) => {
  console.log("arrived here!");

  switch (action.type) {
    case GET_VEHICLES_UPDATED_POSITIONS:
      console.log("action.payload");
      console.log(action.payload);
      return action.payload;
    case LOAD_VEHICLES_UPDATED_POSITIONS:
      return null;
    default:
      return state;
  }
};

export default vehicleUpdatedPositionReducer;
