import React from "react";

import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useTheme } from "@mui/material/styles";

export default function MyLocationButton({
  bottomSheetHeight,
  isBottomSheetMoving,
  getUserPosition,
  map,
}) {
  const theme = useTheme();
  return (
    <Zoom
      style={{
        position: "absolute",
        right: 0,
        bottom:
          bottomSheetHeight === 0
            ? bottomSheetHeight
            : bottomSheetHeight - theme.mixins.toolbar.minHeight,
        margin: 8,
        marginBottom: 16,
      }}
      in={!isBottomSheetMoving}
    >
      <Fab
        color="primary"
        aria-label="location"
        onClick={() => getUserPosition(map)}
      >
        <MyLocationIcon />
      </Fab>
    </Zoom>
  );
}
