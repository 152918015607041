import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker } from "@react-google-maps/api";

import { getStopSchedule } from "../redux/actions";

import busstation from "../assets/busstation.png";
import currentmetrostation from "../assets/currentmetrostation.png";
import currentstation from "../assets/currentstation.png";
import metrostation from "../assets/metrostation.png";

export default function StopMarkers({ registerPositionWatcher, bounds, zoom }) {
  const dispatch = useDispatch();
  const stops = useSelector((state) => state.stops.stops);
  const selectedStop = useSelector((state) => state.stops.selectedStop);

  if (!stops) {
    return null;
  }

  if (zoom < 16) {
    return null;
  }

  return stops.map((element) => {
    let isMetro = element.stopUrl.includes("/metro/");
    let visible = false;

    if (
      element.stopLat < bounds.ne.lat &&
      element.stopLat > bounds.sw.lat &&
      element.stopLon < bounds.ne.lng &&
      element.stopLon > bounds.sw.lng
    ) {
      visible = true;
    }

    const position = {
      lat: element.stopLat,
      lng: element.stopLon,
    };
    let zIndex = 10;

    let iconURL = busstation;

    if (isMetro) {
      iconURL = metrostation;
    }

    if (selectedStop && selectedStop.stopId === element.stopId) {
      zIndex = 50;
      iconURL = currentstation;
      if (isMetro) {
        iconURL = currentmetrostation;
      }
    }

    return visible ? (
      <Marker
        key={element.stopId}
        position={position}
        icon={iconURL}
        onClick={() => {
          registerPositionWatcher();
          dispatch(getStopSchedule(element));
        }}
        zIndex={zIndex}
      />
    ) : null;
  });
}
