import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";

const ohBusApi = axios.create({
  baseURL: "https://mtl-bus-metro-train-sched.azurewebsites.net/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

ohBusApi.interceptors.request.use(
  (successfulReq) => {
    successfulReq.headers.Authorization = `Bearer ${reactLocalStorage.get(
      "token"
    )}`;
    return successfulReq;
  },
  (error) => {
    console.error(`Http call failed: ${error}`);
  }
);

export default ohBusApi;
