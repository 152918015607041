import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Marker } from "@react-google-maps/api";

import { getVehiclesPositions } from "../redux/actions";

export default function VehicleMarkers() {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule);
  const vehiclesPositions = useSelector((state) => state.vehicle);

  useEffect(() => {
    if (!schedule || schedule === "ERROR") {
      return;
    }

    let stopIds = [];
    schedule.forEach((element) => {
      stopIds.push(element.trips.tripId);
    });

    dispatch(getVehiclesPositions(stopIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  if (!vehiclesPositions || vehiclesPositions.length === 0) {
    return null;
  }

  return vehiclesPositions.map((element) => {
    let position = {
      lat: element.vehicle.position.latitude,
      lng: element.vehicle.position.longitude,
    };
    return (
      <Marker
        key={element.id}
        position={position}
        label={{
          text: element.vehicle.trip.routeId,
          color: "white",
        }}
        zIndex={49}
      />
    );
  });
}
