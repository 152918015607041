import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ListSubheader } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";

import { useTranslation } from "react-i18next";

export default function MetroElevatorServiceStatus() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const metroService = useSelector((state) => state.metro);

  if (!metroService) return <div>Loading...</div>;

  let elevatorServiceArrayTrue = [];
  let elevatorServiceArrayFalse = [];

  for (const [key, element] of Object.entries(metroService.elevators)) {
    if (element.status === true) {
      elevatorServiceArrayTrue.push(
        <ListItem key={key}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: green[500] }}>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={key} secondary={t("noIssuesAtThisStation")} />
        </ListItem>
      );
    } else if (element.status === false) {
      elevatorServiceArrayFalse.push(
        <ListItem key={key}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: red[500] }}>
              <CloseIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={key} secondary={element.text} />
        </ListItem>
      );
    }
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: 0, bottom: "auto" }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
              onClick={() => navigate("/metro")}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {t("elevatorStatus")}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      {elevatorServiceArrayFalse.length > 0 && (
        <List
          style={{ marginTop: theme.mixins.toolbar.minHeight }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {t("disruptedServices")}
            </ListSubheader>
          }
        >
          {elevatorServiceArrayFalse}
        </List>
      )}
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("normalServices")}
          </ListSubheader>
        }
        style={{
          marginTop:
            elevatorServiceArrayFalse.length === 0
              ? theme.mixins.toolbar.minHeight
              : 0,
        }}
      >
        {elevatorServiceArrayTrue}
      </List>
    </>
  );
}
