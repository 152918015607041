import React from "react";
import { Routes as DOMRoutes, Route } from "react-router-dom";
import { useFirebaseApp, useInitPerformance } from "reactfire";
import { signInAnonymously, getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { reactLocalStorage } from "reactjs-localstorage";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import MetroElevatorServiceStatus from "./MetroElevatorServiceStatus";
import { getMetroServiceAlerts } from "../redux/actions";
import { getServiceAlerts, getAllStopsLocations } from "../redux/actions";

import Home from "./Home";
import Settings from "./Settings";
import Metro from "./Metro";
import SimpleBottomNavigation from "./SimpleBottomNavigation";

import { useTranslation } from "react-i18next";

export default function Routes() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const messaging = getMessaging(firebaseApp);

  const [userSignedIn, setUserSignedIn] = React.useState(false);

  const stops = useSelector((state) => state.stops.stops);
  const metroService = useSelector((state) => state.metro);
  const serviceAlerts = useSelector((state) => state.serviceAlerts);

  React.useEffect(() => {
    let unsubscribe;

    const signInFlow = async () => {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const token = await user.getIdTokenResult(true);
          reactLocalStorage.set("token", token.token);
          if (!serviceAlerts) {
            dispatch(getServiceAlerts());
          }

          if (!stops) {
            dispatch(getAllStopsLocations());
          }
          if (!metroService) {
            dispatch(getMetroServiceAlerts());
          }

          setUserSignedIn(true);

          try {
            console.log(messaging);
            const token = await getToken(messaging, {
              vapidKey:
                "BDkbfN-qH-mtVM5X2QlRM61LzhgyZas9T02g-GfN0QIRVXkPHgFOoJVdRj78NNUYO3FPZP_lWUEOxQ6DbPeJInE",
            });
            console.log("Your token is:", token);
          } catch (error) {
            console.error(error);
          }

          onMessage(messaging, (payload) => {
            console.log("Message received. ");
            // ...
          });
        } else {
          // User is signed out
          setUserSignedIn(false);
          await signInAnonymously(auth);
        }
      });
    };

    signInFlow();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(firebaseApp);
  });

  if (
    stops === "ERROR" ||
    metroService === "ERROR" ||
    serviceAlerts === "ERROR"
  ) {
    return (
      <div
        style={{
          height: `100vh`,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            msTransform: "translateY(-50%)",
            transform: "translateY(-50%)",
            width: "100%",
          }}
        >
          <Typography variant="h1" align="center">
            (⊙.☉)7
          </Typography>
          <Alert variant="filled" severity="error" style={{ margin: "16px" }}>
            {t("errorMessage1")}
          </Alert>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ margin: 4 }}
              variant="contained"
              onClick={() => window.location.reload(false)}
            >
              {t("tryAgain")}
            </Button>
          </div>
          <Typography variant="h1" align="center">
            ◔_◔
          </Typography>
        </div>
      </div>
    );
  }

  if (!userSignedIn && !stops && !metroService) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Stack alignItems="center">
          <CircularProgress size={60} style={{ marginBottom: 16 }} />
          <Typography align="center">{t("loadingData")}</Typography>
        </Stack>
      </div>
    );
  }

  return (
    <>
      <DOMRoutes>
        <Route path="/" element={<SimpleBottomNavigation />}>
          <Route index element={<Home />} />
          <Route path="/metro" element={<Metro />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route
          path="/elevatorstatus"
          element={<MetroElevatorServiceStatus />}
        ></Route>
      </DOMRoutes>
    </>
  );
}
