import React from "react";
import { useSelector } from "react-redux";
import { Polyline } from "@react-google-maps/api";

export default function ShapesPolylines() {
  const shapes = useSelector((state) => state.shapes);

  const lineSymbol = {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 1,
    strokeColor: "White",
  };

  if (!shapes) {
    return <></>;
  }

  let dict = {};
  shapes.forEach((element) => {
    if (dict[element.shapeId]) {
      dict[element.shapeId].push({
        lat: element.shapePtLat,
        lng: element.shapePtLon,
      });
    } else {
      dict[element.shapeId] = [
        { lat: element.shapePtLat, lng: element.shapePtLon },
      ];
    }
  });

  let valueArray = [];

  for (var key in dict) {
    var value = dict[key];
    valueArray.push(value);
  }
  let keyCounter = 0;
  return valueArray.map((element) => {
    keyCounter = keyCounter + 1;
    return (
      <Polyline
        key={keyCounter.toString()}
        path={element}
        options={{
          strokeColor: "DodgerBlue",
          strokeWeight: 10,
          icons: [
            {
              icon: lineSymbol,
              repeat: "1%",
            },
          ],
        }}
      />
    );
  });
}
