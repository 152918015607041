import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { Divider, Skeleton } from "@mui/material";

export default function ScheduleBottomSheetLoadingSkeleton() {
  let skel = ["1", "2", "3", "4", "5"];
  return skel.map((element) => {
    return (
      <div key={element}>
        <ListItem disablePadding key={element}>
          <ListItemButton>
            <ListItemIcon>
              <DirectionsBusIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Skeleton
                  variant="rectangular"
                  width={210}
                  height={10}
                  style={{ marginBottom: 10 }}
                />
              }
              secondary={
                <Skeleton variant="rectangular" width={150} height={10} />
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
    );
  });
}
