import * as React from "react";

import { useDispatch } from "react-redux";
import List from "@mui/material/List";
import { Button, Divider } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";

import { getStopSchedule } from "../redux/actions";

import { getDatabase, ref as firebaseDBRef, remove } from "firebase/database";
import { useFirebaseApp, useSigninCheck } from "reactfire";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FavoriteSmallSchedule from "./FavoriteSmallSchedule";
import ohBusApi from "../apis/montrealData";
import { LoadingButton } from "@mui/lab";

import { useTranslation } from "react-i18next";

export default function FavoritesCard({ uniqueKey, value, toggleDrawer }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: signinResult } = useSigninCheck();
  const firebaseApp = useFirebaseApp();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [schedule, setSchedule] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const database = getDatabase(firebaseApp);

  const removeBookmark = (uniqueKey) => {
    setAnchorEl(null);

    const bookmarkUserRef = firebaseDBRef(
      database,
      `bookmarks/${signinResult?.user.uid}/${uniqueKey}`
    );
    remove(bookmarkUserRef);
  };

  const getStopMiniSchedule = () => {
    setLoading(true);
    ohBusApi
      .get(`StopSchedule?stopdId=${uniqueKey}&noHistory=true&limit=3`)
      .then((response) => {
        setSchedule(response.data);
        setLoading(false);
      })
      .catch(() => {
        console.error("Something went wrong getting the mini schedule");
        setSchedule([]);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getStopMiniSchedule();
  }, [uniqueKey]);

  return (
    <>
      <Card key={uniqueKey.toString()} sx={{ marginBottom: 2 }} elevation={0}>
        <CardHeader
          avatar={
            <Avatar variant="rounded" sx={{ bgcolor: "#ff0099" }}>
              <DirectionsBusIcon />
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={value.stopName}
          subheader={
            value.wheelchairBoarding === 1
              ? t("accessible")
              : t("notAccessible")
          }
        />
        <CardContent>
          <List disablePadding dense>
            <Divider />
            <FavoriteSmallSchedule
              stopId={value.stopId}
              loading={loading}
              schedule={schedule}
            />
          </List>
        </CardContent>
        <CardActions>
          <LoadingButton
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={getStopMiniSchedule}
            loading={loading}
            loadingPosition="start"
          >
            {t("refresh")}
          </LoadingButton>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              toggleDrawer("right", false)();
              dispatch(getStopSchedule(value));
            }}
          >
            {t("more")}
          </Button>
        </CardActions>
      </Card>
      <Divider />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => removeBookmark(uniqueKey)}>
          {t("remove")}
        </MenuItem>
      </Menu>
    </>
  );
}
