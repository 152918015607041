import {
  OPEN_BOTTOM_SHEET,
  CLOSE_BOTTOM_SHEET,
  SET_BOTTOM_SHEET_HEIGHT,
  SET_BOTTOM_SHEET_MOVING,
} from "../actions/types";

const bottomSheetReducer = (
  state = { toggle: false, height: 0, isMoving: false },
  action
) => {
  switch (action.type) {
    case OPEN_BOTTOM_SHEET:
      return { ...state, toggle: true };
    case CLOSE_BOTTOM_SHEET:
      return { ...state, toggle: false };
    case SET_BOTTOM_SHEET_HEIGHT:
      return { ...state, height: action.payload };
    case SET_BOTTOM_SHEET_MOVING:
      return { ...state, isMoving: action.payload };
    default:
      return state;
  }
};

export default bottomSheetReducer;
