import { SET_MAP_ZOOM } from "../actions/types";

const mapReducer = (state = 17, action) => {
  switch (action.type) {
    case SET_MAP_ZOOM:
      return action.payload;
    default:
      return state;
  }
};

export default mapReducer;
