import { LOAD_METRO_SERVICE, GET_METRO_SERVICE } from "../actions/types";

const metroReducer = (state = null, action) => {
  switch (action.type) {
    case LOAD_METRO_SERVICE:
      return null;
    case GET_METRO_SERVICE:
      return action.payload;
    default:
      return state;
  }
};

export default metroReducer;
