import {
  GET_VEHICLES_POSITIONS,
  LOAD_VEHICLES_POSITIONS,
  CLEAR_VEHICLES_POSITIONS,
} from "../actions/types";

const vehicleReducer = (state = null, action) => {
  switch (action.type) {
    case GET_VEHICLES_POSITIONS:
      return action.payload;
    case LOAD_VEHICLES_POSITIONS:
      return null;
    case CLEAR_VEHICLES_POSITIONS:
      return null;
    default:
      return state;
  }
};

export default vehicleReducer;
