import * as React from "react";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import { Slide } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Avatar from "@mui/material/Avatar";

import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";

import { getDatabase, ref as firebaseDBRef, onValue } from "firebase/database";
import { useSigninCheck } from "reactfire";

import FavoritesBlank from "./FavoritesBlank";
import FavoritesCard from "./FavoritesCard";

export default function FavoritesDrawer({ isBottomSheetMoving }) {
  const { data: signinResult } = useSigninCheck();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [userBookmarks, setUserBookmarks] = React.useState({});

  React.useEffect(() => {
    const db = getDatabase();
    const userBookmarksRef = firebaseDBRef(
      db,
      `bookmarks/${signinResult?.user.uid}`
    );
    const unsubscribe = onValue(userBookmarksRef, (snapshot) => {
      const data = snapshot.val();
      setUserBookmarks(data);
    });

    return () => {
      unsubscribe();
    };
  }, [signinResult]);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = () => {
    let listItems = [];

    if (!userBookmarks) {
      return <FavoritesBlank />;
    }

    if (userBookmarks && Object.keys(userBookmarks).length === 0) {
      return <FavoritesBlank />;
    }

    if (userBookmarks)
      for (const [key, value] of Object.entries(userBookmarks)) {
        listItems.push(
          <Collapse key={key.toString()}>
            <FavoritesCard
              uniqueKey={key}
              value={value}
              toggleDrawer={toggleDrawer}
            />
          </Collapse>
        );
      }

    return (
      <List>
        <TransitionGroup>{listItems}</TransitionGroup>
      </List>
    );
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Slide
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              margin: 16,
              marginRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            in={!isBottomSheetMoving}
            direction={"left"}
          >
            <Avatar
              sx={{ bgcolor: "#ff0099" }}
              variant="rounded"
              onClick={toggleDrawer(anchor, true)}
            >
              <BookmarkIcon />
            </Avatar>
          </Slide>
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: { width: "90%", maxWidth: "500px" },
              }}
              swipeAreaWidth={3}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        </React.Fragment>
      ))}
    </div>
  );
}
