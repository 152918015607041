import {
  CLEAR_USER_CREDENTIALS,
  SET_USER_CREDENTIALS,
  SET_USER_LOCATION,
} from "../actions/types";

const userReducer = (
  state = {
    userCredentials: null,
    location: {
      lat: 0,
      lng: 0,
    },
    locationAccuracy: null,
  },
  action
) => {
  switch (action.type) {
    case SET_USER_LOCATION:
      return {
        ...state,
        location: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
        locationAccuracy: action.payload.accuracy,
      };
    case SET_USER_CREDENTIALS:
      return { ...state, userCredentials: action.payload };
    case CLEAR_USER_CREDENTIALS:
      return { ...state, userCredentials: null };
    default:
      return state;
  }
};

export default userReducer;
