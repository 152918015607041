import {
  GET_STOPS_LOCATIONS,
  LOAD_STOPS_LOCATIONS,
  SELECT_STOP_LOCATION,
  CLEAR_SELECTED_STOP,
} from "../actions/types";

const stopsReducer = (state = { selectedStop: null, stops: null }, action) => {
  switch (action.type) {
    case GET_STOPS_LOCATIONS:
      return { ...state, stops: action.payload };
    case LOAD_STOPS_LOCATIONS:
      return { ...state, stops: null };
    case SELECT_STOP_LOCATION:
      return { ...state, selectedStop: action.payload };
    case CLEAR_SELECTED_STOP:
      return { ...state, selectedStop: null };
    default:
      return state;
  }
};

export default stopsReducer;
