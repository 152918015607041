import React from "react";
import { Auth } from "./Auth";

import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import TranslateIcon from "@mui/icons-material/Translate";

import { useTranslation } from "react-i18next";
import { reactLocalStorage } from "reactjs-localstorage";

export default function Schedule() {
  const theme = useTheme();
  const [lang, setLang] = React.useState("en");
  const { i18n, t } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const handleChange = (event) => {
    reactLocalStorage.set("lang", event.target.value, true);
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  React.useEffect(() => {
    const language = reactLocalStorage.get("lang");
    if (language) {
      setLang(language);
    } else {
      setLang("en");
    }
  }, []);

  return (
    <div
      style={{
        height: `Calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
          width: "100%",
        }}
      >
        <List
          sx={{ width: "100%" }}
          subheader={<ListSubheader>{t("profile")}</ListSubheader>}
        >
          <Auth />
        </List>

        <List
          sx={{ width: "100%" }}
          subheader={<ListSubheader>{t("settings")}</ListSubheader>}
        >
          <ListItem>
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={t("language")} />
            <Select
              id="demo-simple-select"
              value={lang}
              onChange={handleChange}
              sx={{ minWidth: 100 }}
              autoWidth
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"fr"}>Français</MenuItem>
            </Select>
          </ListItem>
        </List>
      </div>
    </div>
  );
}
