import { combineReducers } from "redux";
import stopsReducer from "./stopsReducer";
import scheduleReducer from "./scheduleReducer";
import vehicleReducer from "./vehicleReducer";
import vehicleUpdatedPositionReducer from "./vehicleUpdatedPositionReducer";
import mapReducer from "./mapReducer";
import bottomSheetReducer from "./bottomSheetReducer";
import userReducer from "./userReducer";
import shapesReducer from "./shapesReducer";
import serviceAlertsReducer from "./serviceAlertsReducer";
import metroReducer from "./metroReducer";

export default combineReducers({
  stops: stopsReducer,
  schedule: scheduleReducer,
  vehicle: vehicleReducer,
  vehicleUpdatedPosition: vehicleUpdatedPositionReducer,
  map: mapReducer,
  bottomSheet: bottomSheetReducer,
  user: userReducer,
  shapes: shapesReducer,
  serviceAlerts: serviceAlertsReducer,
  metro: metroReducer,
});
