import React from "react";
import { useSelector } from "react-redux";
import { Marker, Circle } from "@react-google-maps/api";

export default function UserLocationMarker() {
  const location = useSelector((state) => state.user.location);
  const accuracy = useSelector((state) => state.user.locationAccuracy);

  return (
    <>
      <Marker
        position={location}
        icon={{
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillOpacity: 1,
          strokeWeight: 3,
          fillColor: "#5384ED",
          strokeColor: "#ffffff",
        }}
      />
      <Circle
        center={location}
        radius={accuracy}
        options={{
          fillColor: "#5384ED",
          strokeColor: "#ffffff",
          strokeWeight: 0,
          fillOpacity: 0.3,
        }}
      />
    </>
  );
}
