import React from "react";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function ZoomInToShowStopsButton({
  showRefreshButton,
  isBottomSheetMoving,
  bottomSheetHeight,
  zoomIn,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Zoom
      in={showRefreshButton && !isBottomSheetMoving}
      timeout={300}
      style={{
        position: "absolute",
        bottom:
          bottomSheetHeight === 0
            ? bottomSheetHeight
            : bottomSheetHeight - theme.mixins.toolbar.minHeight,
        margin: 8,
        marginBottom: 16,
      }}
    >
      <Fab
        variant="extended"
        color="primary"
        aria-label="Zoom in to show stops"
        onClick={() => {
          zoomIn();
        }}
      >
        <ZoomInIcon />
        {t("zoomStops")}
      </Fab>
    </Zoom>
  );
}
